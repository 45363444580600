import React from 'react';
import { graphql, Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import cn from 'classnames';

import { LogoIconColor } from 'assets';

import { drids, ROUTES } from '_constants';

import { Page } from 'components/_shared/Page';

import { useStrapiCommonContent } from 'hooks';
import { parseMarkdown, sortByKey, truncateText } from 'utils';

import 'styles/PageNews.styles.scss';

const PostLink = ({ className, children, post = {}, ...props }) => {
  const _className = cn('news-post-link', className);

  if (post.publisher_news_url) {
    return (
      <a
        {...props}
        className={_className}
        href={post.publisher_news_url}
        target='_blank'
        rel='noreferrer'
      >
        {children || <span className='sr-only'>{post.title}</span>}
      </a>
    );
  }
  return (
    <Link
      {...props}
      className={_className}
      to={ROUTES.NEWS_BY_TITLE(post.slug)}
    >
      {children}
    </Link>
  );
};

export default function PageNews(props) {
  const {
    page,
    allStrapiNewsArticle: { posts },
    allStrapiNewsSource: { publishers }
  } = props.data;

  const commonContent = useStrapiCommonContent();

  const truncateExcerpt = (excerpt, postIndex) => {
    return truncateText(excerpt, postIndex === 0 ? 380 : 188);
  };

  const renderPosts = sortByKey(posts).map((post, i) => {
    const renderThumbnail = () => {
      const thumbnail = post.banner?.localFile;
      const publisher = publishers.find(publisher => {
        return (
          publisher.publisher_name.toLowerCase() ===
          post.publisher_name?.toLowerCase()
        );
      });

      if (thumbnail && (i < 5 || !post.publisher_name)) {
        return (
          <div className='news-post-thumbnail image'>
            <GatsbyImage image={getImage(thumbnail)} alt={post.title} />
          </div>
        );
      }

      if (publisher) {
        return (
          <div
            className='news-post-thumbnail publisher'
            style={{
              backgroundColor: `#${publisher.publisher_logo_bg_color}`
            }}
          >
            <GatsbyImage
              image={getImage(publisher.publisher_logo?.localFile)}
              alt={post.title}
            />
          </div>
        );
      }

      return (
        <div className='news-post-thumbnail default'>
          <LogoIconColor className='devrev-logo-icon' />
        </div>
      );
    };

    return (
      <article key={post.slug} className='news-post'>
        {renderThumbnail()}
        <div className='news-post-content'>
          <h4 className='news-post-title'>{post.title}</h4>

          {post.news_date && (
            <time className='news-post-date' dateTime={post.news_date}>
              {post.news_date}
            </time>
          )}

          {post.starting_body?.data?.starting_body && (
            <div className='news-post-excerpt'>
              {parseMarkdown(
                truncateExcerpt(post.starting_body.data.starting_body, i),
                {
                  wrapper: false
                }
              )}
            </div>
          )}

          <PostLink
            className='news-post-link-continue hover:underline'
            post={post}
          >
            {post.publisher_name
              ? `${commonContent.continue_on} ${post.publisher_name} ${commonContent.arrow}`
              : `${commonContent.read_more} ${commonContent.arrow}`}
          </PostLink>
        </div>

        <PostLink
          className='news-post-link-overlay'
          data-drid={drids.NEWS_BLOG_NAV.ARTICLES}
          post={post}
        />
      </article>
    );
  });

  return (
    <Page
      className='page-news'
      meta={{
        title: page.seotitle,
        description: page.seodescription,
        image: page.preview_img[0]?.url
      }}
      drid={drids.PAGE.NEWS}
    >
      <div className='header-spacer h-24' />
      <div className='w-full max-w-devrev-extra mx-auto flex flex-col items-center md:items-start pt-6 px-3 md:px-6 relative lg:pt-12 lg:flex-row'>
        <div className='lg:w-80 lg:mr-12 flex-shrink-0'>
          <div className='buggy-align text-3xl flex justify-center text-center md:text-left md:justify-start pb-6 md:text-4xl lg:text-5xl leading-none'>
            <h2 className='font-title'>{page.newsroom_title}</h2>
          </div>
          <div className='buggy-align mx-auto w-5/6 md:w-full mb-12 lg:m-0 text-center md:text-left text-lg px-3 md:px-0 lg:max-width-none leading-tight'>
            {parseMarkdown(page.newsroom_desc.data.newsroom_desc)}
          </div>
        </div>
        <section className='news-list'>{renderPosts}</section>
      </div>
    </Page>
  );
}

export const query = graphql`
  query PageNewsQuery {
    page: strapiNewsroom {
      newsroom_desc {
        data {
          newsroom_desc
        }
      }
      newsroom_title
      seodescription
      seotitle
      preview_img {
        url
      }
    }

    allStrapiNewsArticle {
      posts: nodes {
        id
        title
        slug
        news_date(formatString: "DD MMM YYYY")
        starting_body {
          data {
            starting_body
          }
        }
        publisher_name
        publisher_news_url
        order
        banner {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, quality: 80, width: 1440)
            }
          }
        }
      }
    }

    allStrapiNewsSource {
      publishers: nodes {
        publisher_logo_bg_color
        publisher_name
        publisher_logo {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, quality: 80, width: 1440)
            }
          }
        }
      }
    }
  }
`;
